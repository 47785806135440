import CountrySelectorMenu from './CountrySelectorMenu';
import './styles.scss';
import HeaderButton from '../HeaderButton';
import React, { useEffect, useRef } from 'react';
import { selectTranslations } from 'store/slices/GeneralTranslationsSlice';
import { useSelector } from 'react-redux';

const CountrySelector = ({ isOpen, setIsOpen }) => {
    const { menu_change_country_text } = useSelector(selectTranslations);
    const ref = useRef(null);

    const onToggleMenu = () => setIsOpen(!isOpen);

    useEffect(() => {
        const handleClickOutside = e => {
            const isClickDropdownItem = e.target.matches('li.body1');

            if (ref.current && !ref.current.contains(e.target) && !isClickDropdownItem) {
                setIsOpen(false);
            }
        };

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return (
        <div ref={ref} className="country-selector-container">
            <HeaderButton iconName="globe" text={'International'} />
            {/* {isOpen && <CountrySelectorMenu onClose={onToggleMenu} />} */}
        </div>
    );
};

export default CountrySelector;
